<template>
    <div class="flex flex-col items-center  mx-auto">
        <div class="w-full px-0">
            <h5 v-if="title" class="dark:text-white text-black">{{ title }}</h5>
            <div class="flex flex-col items-center relative">
                <div class="w-full  svelte-1l8159u">
                    <div
                        class="mt-2 p-1 flex border dark:border-gray-700 border-gray-200 dark:bg-gray-800 bg-white rounded svelte-1l8159u">
                        <div class="flex flex-auto flex-wrap">
                            <div v-for="select in selected" :key="select.id"
                                 class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-200 border border-teal-300 ">
                                <div class="text-xs font-normal leading-none max-w-full flex-initial">{{
                                        select[name]
                                    }}
                                </div>
                                <div class="flex flex-auto flex-row-reverse">
                                    <div @click="remove(select.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-1">
                                <input @focusin="showList = true" @focusout="showList = false" placeholder=""
                                       class="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800">
                            </div>
                        </div>
                        <div
                            class="dark:text-white text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 svelte-1l8159u">
                            <button @click.prevent="showList = !showList" :class="{' rotate-180': showList}"
                                    class="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none transform transition transition-all duration-500">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round"
                                     class="feather feather-chevron-up w-4 h-4">
                                    <polyline points="18 15 12 9 6 15"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <transition name="fade">
                    <div v-show="showList"
                         class=" absolute shadow top-100 dark:bg-gray-700 bg-white z-40 w-full left-0 rounded max-h-select overflow-y-auto svelte-5uyqqj">
                        <div v-for="item in items" :key="item.id" class="flex flex-col w-full"
                             :class="{' border-l-2  border-teal-600': selectedIds.includes(item.id)}">
                            <div @click="select(item)"
                                 class="cursor-pointer w-full dark:border-gray-600 border-gray-100 rounded-t border-b dark:hover:bg-gray-800 hover:bg-teal-100 ">
                                <div
                                    class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                    <div class="w-full items-center flex">
                                        <div class="mx-2 leading-6 dark:text-white text-gray-900 ">{{ item[name] }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </transition>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomSelect",
    data: () => ({
        showList: false,
        selected: [],
        selectedIds: [],
    }),
    props: {
        items: {
            required: true,
            type: Array
        },
        title: {
            type: String
        },
        single: {
            type: Boolean
        },
        name:{
            type:String
        }
    },
    methods: {
        remove(id) {
            if (!this.single) {
                this.selected = this.selected.filter(i => {
                    return i.id !== id
                })
                this.selectedIds = this.selected.map(i => i.id)
            }else {
                this.selected = []
                this.selectedIds =[]
            }

            this.$emit('input', this.selectedIds)
        },
        select(item) {
            if (!this.single) {
                if (!this.selected.includes(item)) {
                    this.selected = [...this.selected, item]
                } else {
                    this.selected = this.selected.filter(i => {
                        return i.id !== item.id
                    })
                }
                this.selectedIds = this.selected.map(i => i.id)
            }
            else {
                this.selected = [item]
                this.selectedIds = this.selected.map(i => i.id)
            }

            this.$emit('input', this.selectedIds)
        }
    }
}
</script>

<style scoped>
.top-100 {
    top: 100%
}

::-webkit-scrollbar {
    display: none;
}

.bottom-100 {
    bottom: 100%
}

.max-h-select {
    max-height: 300px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
    opacity: 0;
}
</style>