<template>

    <div class="lg:container mx-auto pb-24">

        <div class="sm:block lg:flex flex-wrap  mx-auto">
            <h1 class=" text-4xl m-3 dark:text-white text-black">
                Вакансии : {{ itemsFiltered.length }}
            </h1>
            <div class="flex flex-wrap">
                <div v-for="item in itemsFiltered" :key="item.id"
                     class=" sm:w-full lg:w-1/2 my-3 dark:text-white text-gray-700">
                    <div
                        class="card m-3 p-5 dark:bg-gray-800 bg-blue-100 rounded-md h-full md:flex-row flex-col flex gap-x-4">

                        <div class="sm:w-full md:w-1/2">
                            <h3 class="text-lg mb-2  block">
                                {{ item.title }}
                            </h3>
                            <span class="text-gray-400  block mb-2">
                            {{ item.filial }}
                            </span>
                            <span class="text-gray-400 block mb-2">
                            {{ item.salary }}
                            </span>
                            <span class="text-gray-400 block">
                            {{ item.created_at }}
                            </span>

                        </div>


                        <div class="sm:w-full flex content-center md:w-1/2">
                            <div class="sm:w-full text-center justify-end items-center flex ">
                                <router-link :to="{name:'CareerDetail', params:{code:item.code}}" tag="a"
                                             class="inline-flex  detail-link">
                                    Подробнее
                                    <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt="Белтаможсервис">
                                </router-link>
                            </div>
                        </div>



                    </div>
                </div>
            </div>


        </div>

    </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "CareerList",
    computed: {
        ...mapGetters('career', ['items']),
        itemsFiltered() {
            if (this.filials.length || this.types.length) {
                let items = this.items
                if (this.filials.length) {
                    items = items.filter(i => this.filials.includes(i.filial_id))
                }
                if (this.types.length) {
                    items = items.filter(i => this.types.includes(i.type_id))
                }
                return items

            }
            return this.items
        }
    },
    props: {
        filials: {
            type: Array
        },
        types: {
            type: Array
        }
    },
}
</script>

<style scoped>

.custom-style >>> * {
    line-height: inherit !important;
    font-family: "Montserrat", sans-serif !important;
    font-style: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;


}

</style>