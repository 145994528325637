<template>
    <div class="dark:bg-gray-900 bg-white pb-10">
        <div class="pt-24 lg:container mx-auto">
            <div class="mx-auto flex sm:flex-col md:flex-row justify-between mb-7">
                <h1 class="text-5xl  mx-3 dark:text-white text-black block ">Актуальные вакансии</h1>
                <a class="dark:bg-green-400 bg-blue-900 px-3 mx-3 rounded text-white flex content-center items-center sm:my-5 sm:py-3 md:my-0 md:py-0" href="#career-form">Отправить резюме</a>
            </div>

            <div class="flex sm:flex-col lg:flex-row flex-wrap  lg:mx-auto">
                <CustomSelect v-model="selectedFilials" class="sm:w-full lg:w-1/2 px-3" :items="filials" name="name"
                              title="Филиал"></CustomSelect>
                <CustomSelect v-model="selectedTypes" class="sm:w-full lg:w-1/2 px-3" :items="types" name="name" title="Cфера"></CustomSelect>
            </div>
        </div>
        <CareerList :filials="selectedFilials" :types="selectedTypes"></CareerList>

        <CareerForm class="sm:pt-12 lg:pt-24"></CareerForm>
    </div>
</template>

<script>
import CustomSelect from '../../components/common/CustomSelect'
import CareerList from '../../components/career/CareerList';
import CareerForm from '../../components/career/CareerForm';
import {mapGetters} from "vuex";

export default {
    name: "Career",
    meta: {
        title: 'Вакансии'
    },
    data: () => ({
        selectedFilials: [],
        selectedTypes: [],
    }),
    components: {
        CareerList, CustomSelect, CareerForm
    },
    computed: {
        ...mapGetters('career', ['filials', 'types'])
    },

    created() {
        this.$store.dispatch('career/fetchItems')
    }
}
</script>

<style scoped>

</style>