<template>
    <div class="lg:container mx-auto dark:text-white" id="career-form">
        <div class="flex sm:flex-col md:flex-row mx-3">
            <div class="sm:w-full md:w-1/2">
                <h3 class="text-2xl mb-5">Отправьте нам резюме</h3>
                <input type="text" v-model="name"
                       class="p-3 dark:bg-gray-800 bg-white border dark:border-transparent border-gray-400 dark:text-white text-black block w-full rounded outline-none mb-2"
                       placeholder="ФИО">
                <small class="text-red-600"
                       v-if="!$v.name.required && $v.name.$dirty">{{
                        $t('forms.validation.required')
                    }}</small>
                <input type="text" v-model="phone"
                       class="p-3 dark:bg-gray-800 bg-white border dark:border-transparent border-gray-400 dark:text-white text-black block w-full rounded outline-none mb-2"
                       placeholder="Контактный телефон">
                <small class="text-red-600"
                       v-if="!$v.phone.required && $v.phone.$dirty">{{
                        $t('forms.validation.required')
                    }}</small>
                <textarea name="" placeholder="Текст сообщения" v-model="message"
                          class="p-3 border dark:border-transparent border-gray-400 dark:bg-gray-800 bg-white  dark:text-white text-black block w-full rounded outline-none mb-2"
                          id="" cols="30" rows="3"></textarea>
                <small class="text-red-600"
                       v-if="!$v.message.required && $v.message.$dirty">{{
                        $t('forms.validation.required')
                    }}</small>

                <input @change="setFile"
                       class="mb-2 block w-full cursor-pointer dark:bg-gray-800 bg-white dark:text-white text-gray-900 outline-none border dark:border-transparent border-gray-400 text-sm rounded"
                       ref="cv" type="file">


                <label class="block mb-2 p-3"><input v-model="selectFilial" type="checkbox" class="mr-2">Направить в
                    конкретный филиал</label>

                <transition name="slide">


                    <div v-show="selectFilial">
                        <select v-model="filial"
                                class="overflow-hidden mb-2 w-full rounded p-3 outline-none dark:bg-gray-800 bg-white border border-gray-400 dark:border-transparent">
                            <option value="" disabled selected>Филиал</option>
                            <option value="2150">Центральный аппарат</option>
                            <option value="2152">Брест</option>
                            <option value="2153">Витебск</option>
                            <option value="2154">Гомель</option>
                            <option value="2157">Гродно</option>
                            <option value="2151">Минск</option>
                            <option value="2156">Могилев</option>
                        </select>
                    </div>

                </transition>
                <label class="block mb-2 p-3"><input type="checkbox" class="mr-2" v-model="agree">Согласен с политикой
                    конфиденциальности и обработки данных</label>

                <div class="flex">
                    <button class=" py-3 px-5 mr-2 rounded text-white"
                            :class="{'bg-green-300 cursor-pointer':agree === true,'bg-gray-700 cursor-not-allowed':agree === false }"
                            :disabled="agree === false" @click="submit">Отправить
                    </button>
                    <button class="bg-blue-400 py-3 px-5 rounded text-white" @click="clear">Сбросить</button>
                </div>
            </div>
            <div class="sm:w-full md:w-1/2 sm:mt-5 md:mt-0">
                <img v-show="dark" src="@/assets/img/iso/career-dark.svg" alt="career">
                <img v-show="!dark" src="@/assets/img/iso/career.svg" alt="career">
            </div>
        </div>

    </div>

</template>

<script>
import axios from "axios";
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

export default {
    name: "CareerForm",
    mixins: [validationMixin],
    data: () => ({
        name: '',
        phone: '',
        message: '',
        selectFilial: false,
        filial: '',
        agree: false,
        file: {}
    }),
    computed: {
        dark() {
            return this.$store.getters['darkTheme']
        }
    },
    methods: {
        setFile(e) {
            this.file = e.target.files[0]
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                let payload = {
                    name: this.name,
                    phone: this.phone,
                    message: this.message,
                    filial: this.filial,
                    agree: this.agree
                }

                let data = new FormData()
                data.append('form', JSON.stringify(payload))
                data.append('cv', this.file)
                axios.post('https://declarant.by/rest/forms/career', data)
                    .then(res => {
                        this.$notify({
                            title: 'Успешно',
                            text: res.data.data,
                            type: 'success'
                        })
                        this.clear()

                    }).catch(err => {
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
            }
        },
        clear() {
            this.$v.$reset()
            this.name = ''
            this.phone= ''
            this.message=  ''
            this.selectFilial = false
            this.filial = ''
            this.agree =  false
            this.file=  {}
        },
    },
    validations: {
        name: {required,},
        phone: {required,},
        message: {required,},
        agree: {required,}
    }
}
</script>

<style scoped>
input[type=checkbox] {
    transform: scale(1.2);
}

input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
    @apply text-white dark:bg-gray-800 bg-gray-700 hover:bg-gray-600 font-medium text-sm cursor-pointer border-0 py-3 pl-8 pr-4;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.slide-enter-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0px;
}
</style>